import * as React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HomeBG from "../images/home-bg.jpg";
import { Helmet } from "react-helmet";

const indexPage = () => {
  return (
    <>
      <div
        class="hidden coming soon relative h-screen w-full flex items-center justify-center bg-cover bg-center text-center"
        style={{ backgroundImage: `url(${HomeBG})` }}
      >
        <div class="absolute top-0 right-0 bottom-0 left-0 bg-gray-900 opacity-75"></div>

        <div class="z-50 flex flex-col justify-center items-center text-white w-full h-screen">
          <img
            class="w-1/2"
            src="https://imgix.cosmicjs.com/3c4ef230-6cc4-11ed-8951-b39aeeb44ac4-Logo-Lockup-01.svg"
          />
          <p class="mt-3 font-bold text-base text-pacGreen sm:mt-8 sm:text-xl lg:text-lg xl:text-4xl">
            CARBON OFFSET CALCULATOR | COMING SOON
          </p>{" "}
          <p class="max-w-4xl mx-auto mt-3 text-base text-white sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
            We believe that everyone has the power to positively impact climate
            change and fight global warming. You can do your part today by
            purchasing credits to offset the carbon footprint of your home or
            business.
          </p>{" "}
        </div>
      </div>

      <div class="">
        <main
          class="relative bg-cover"
          style={{ backgroundImage: `url(${HomeBG})` }}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>United Green Energy Credits | Welcome</title>

            <meta
              name="description"
              content="United Green Energy can provide green energy to customers in a multitude of ways from many renewable energy services and products to offsetting credits. Check out our services pages to learn more about how to go green with UGE! "
            />
            <link
              rel="icon"
              href="https://imgix.cosmicjs.com/5ffde510-a9f1-11ec-bd4d-d755cb3d1844-UGE-Icon-Green-01.png"
            />
          </Helmet>
          <div class="hidden md:block">
            <Header />
          </div>
          <div class="md:hidden z-10">
            <Header />
          </div>
          <div>
            <div class="text-center hero pt-10 pb-16 px-4 md:px-0 md:pt-28 md:pb-40 items-center pb-2 lg:overflow-hidden">
              <div class="mx-auto max-w-6xl">
                <div class="items-center">
                  <div class="md:w-full">
                    <img
                      class=""
                      src="https://imgix.cosmicjs.com/3c4ef230-6cc4-11ed-8951-b39aeeb44ac4-Logo-Lockup-01.svg"
                    />
                    <img
                      class=""
                      src="https://imgix.cosmicjs.com/1c11e990-6cc0-11ed-8951-b39aeeb44ac4-title-01.svg"
                    />
                    <p class="mt-3 font-bold text-base text-pacGreen sm:mt-8 sm:text-xl lg:text-lg xl:text-4xl">
                      2023 PAC-12 FOOTBALL CHAMPIONSHIP GAME | DEC 02
                    </p>{" "}
                    <p class="mt-3 text-base text-white sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                      We believe that everyone has the power to positively
                      impact climate change and fight global warming. You can do
                      your part today by purchasing credits to offset the carbon
                      footprint of your home or business.
                    </p>{" "}
                    <div class="mt-8 md:flex justify-center ">
                      <div class="md:w-1/3 rounded-md ">
                        <a
                          href="/offset-residential"
                          class="border-2 border-pacGreen flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-black bg-pacGreen hover:opacity-90 md:py-4 md:text-lg md:px-10"
                        >
                          Residential
                        </a>
                      </div>
                      <div class="md:w-1/3 mt-3 rounded-md  sm:mt-0 sm:ml-3">
                        <a
                          href="/offset-commercial"
                          class="border-2 border-white flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white bg-pacBlue bg-opacity-10 hover:bg-white hover:text-pacBlue md:py-4 md:text-lg md:px-10"
                        >
                          Commercial
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="hidden lg:block pl-10 w-1/2  relative"></div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div class="hidden about UET relative bg-gradient-to-r from-pacBlue to-pacLightBlue pt-10 pb-9">
          <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
            <div class="relative sm:py-16 lg:py-0">
              <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                <div class="relative pt-20 pb-10 rounded-2xl  overflow-hidden">
                  <img
                    class="absolute inset-0 h-full w-full object-cover"
                    src="https://www.unitedenergytrading.com/wp-content/uploads/hero-team.png"
                    alt=""
                  />

                  <div class="absolute inset-0 bg-gradient-to-t from-emerald-900 to-lime-400 mix-blend-multiply"></div>
                  <div class=" relative px-8">
                    <div>
                      <img
                        class="mx-auto"
                        src="https://www.unitedenergytrading.com/wp-content/themes/uet/assets/images/logo-white.svg"
                        alt="Workcation"
                      />
                    </div>
                    <blockquote class="mt-8">
                      <div class="relative text-lg font-medium text-white md:flex-grow">
                        <svg
                          class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-lime-200 opacity-40"
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          aria-hidden="true"
                        >
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        <p class="relative">
                          We understand the importance of sustainability and
                          ESG. As this ever-changing landscape grows, we’ve
                          identified more dynamic and diverse opportunities for
                          our traditional energy customers. From managing carbon
                          emissions to carbon sequestration, we’re excited to
                          offer “bundled” green packages designed to help both
                          private and public clients seamlessly adopt cleaner
                          energy solutions that best fit their needs.
                        </p>
                      </div>

                      <footer class="mt-4">
                        <p class="text-base font-semibold text-lime-200">
                          Thomas Williams, Executive Chairman & Executive Vice
                          President of Business Development
                        </p>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-20 hidden md:block relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
              <div class="">
                <h2 class="text-3xl text-white font-extrabold tracking-tight sm:text-4xl">
                  Your Carbon Offset Renewable Partner
                </h2>
                <div class="mt-6 text-white space-y-6">
                  <p class="text-lg">
                    Our longstanding partnerships with{" "}
                    <a
                      href="https://www.callectiveenergy.com/"
                      target="blank"
                      class="font-bold hover:text-gray-100 hover:underline"
                    >
                      Callective Energy
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.unitedenergytrading.com/"
                      target="blank"
                      class="font-bold hover:text-gray-100 hover:underline"
                    >
                      United Energy Trading (UET)
                    </a>{" "}
                    allow us to continue providing the most cost-effective
                    sustainable green-energy solutions. Through purchasing
                    options like Carbon Offset Green Gas, United Green Energy
                    ensures the communities we service have the best in green
                    energy choices.
                  </p>
                  <p class="text-lg leading-7">
                    United Green Energy offers customers worldwide a multitude
                    of renewable energy services and products to help offset
                    carbon. Since teaming up with{" "}
                    <a
                      href="https://plantit2020.org/"
                      target="blank"
                      class="font-bold hover:text-gray-100 hover:underline"
                    >
                      Plant-It 2020
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.4ocean.com/"
                      target="blank"
                      class="font-bold hover:text-gray-100 hover:underline"
                    >
                      4Ocean
                    </a>{" "}
                    only three years ago, the combined efforts of UGE, UET, and
                    Callective have helped plant over 11,409 trees and remove
                    over 3,500 pounds of trash from our oceans.
                  </p>
                  <p class="text-lg leading-7">
                    Helping the world reach a carbon-neutral status is our goal.
                    Through groundbreaking technology in the renewable and
                    sustainable energy space, we’re approaching our
                    responsibilities to the planet with urgency. We’re honored
                    and excited to be involved in the creation of a cleaner
                    future for the world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden bg-white mb-10">
          <div class="max-w-7xl mx-auto py-12 lg:px-8">
            <h1 class="text-center text-2xl tracking-tight font-medium text-gray-500">
              Companies that have offset their Carbon emissions with us...
            </h1>
            <div class="mt-6 flex justify-center">
              <div class="w-1/3 mx-auto py-8 px-8">
                <a class="flex" href="https://consultfgc.com/" target="blank">
                  <img
                    class="hover:opacity-70"
                    src="https://consultfgc.netlify.app/static/ConsultFGC_Logo-1714096432cc4fd9a8653a11b0a67248.png"
                    alt="Workcation"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="w-1/3 mx-auto rounded-md ">
            <a
              href="/offset-commercial"
              class="flex items-center justify-center px-10 py-5 border border-transparent text-base font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-700 md:py-4 md:text-lg md:px-10"
            >
              Commercial Offsets
            </a>
          </div>
        </div>{" "}
        <Footer />
      </div>
    </>
  );
};

export default indexPage;
/*
 <div
        class="hidden coming soon relative h-screen w-full flex items-center justify-center bg-cover bg-center text-center"
        style={{ backgroundImage: `url(${HomeBG})` }}
      >
        <div class="absolute top-0 right-0 bottom-0 left-0 bg-gray-900 opacity-75"></div>

        <div class="z-50 flex flex-col justify-center items-center text-white w-full h-screen">
          <img
            class="w-1/2"
            src="https://imgix.cosmicjs.com/3c4ef230-6cc4-11ed-8951-b39aeeb44ac4-Logo-Lockup-01.svg"
          />
          <p class="mt-3 font-bold text-base text-pacGreen sm:mt-8 sm:text-xl lg:text-lg xl:text-4xl">
            CARBON OFFSET CALCULATOR | COMING SOON
          </p>{" "}
          <p class="max-w-4xl mx-auto mt-3 text-base text-white sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
            We believe that everyone has the power to positively impact climate
            change and fight global warming. You can do your part today by
            purchasing credits to offset the carbon footprint of your home or
            business.
          </p>{" "}
        </div>
      </div>

      <div class="">
        <main
          class="relative bg-cover"
          style={{ backgroundImage: `url(${HomeBG})` }}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>United Green Energy Credits | Welcome</title>

            <meta
              name="description"
              content="United Green Energy can provide green energy to customers in a multitude of ways from many renewable energy services and products to offsetting credits. Check out our services pages to learn more about how to go green with UGE! "
            />
            <link
              rel="icon"
              href="https://imgix.cosmicjs.com/5ffde510-a9f1-11ec-bd4d-d755cb3d1844-UGE-Icon-Green-01.png"
            />
          </Helmet>
          <div class="hidden md:block">
            <Header />
          </div>
          <div class="md:hidden z-10">
            <Header />
          </div>
          <div>
            <div class="text-center hero pt-10 pb-16 px-4 md:px-0 md:pt-28 md:pb-40 items-center pb-2 lg:overflow-hidden">
              <div class="mx-auto max-w-6xl">
                <div class="items-center">
                  <div class="md:w-full">
                    <img
                      class=""
                      src="https://imgix.cosmicjs.com/3c4ef230-6cc4-11ed-8951-b39aeeb44ac4-Logo-Lockup-01.svg"
                    />
                    <img
                      class=""
                      src="https://imgix.cosmicjs.com/1c11e990-6cc0-11ed-8951-b39aeeb44ac4-title-01.svg"
                    />
                    <p class="mt-3 font-bold text-base text-pacGreen sm:mt-8 sm:text-xl lg:text-lg xl:text-4xl">
                      2023 PAC-12 FOOTBALL CHAMPIONSHIP GAME | DEC 02
                    </p>{" "}
                    <p class="mt-3 text-base text-white sm:mt-8 sm:text-xl lg:text-lg xl:text-xl">
                      We believe that everyone has the power to positively
                      impact climate change and fight global warming. You can do
                      your part today by purchasing credits to offset the carbon
                      footprint of your home or business.
                    </p>{" "}
                    <div class="mt-8 md:flex justify-center ">
                      <div class="md:w-1/3 rounded-md ">
                        <a
                          href="/offset-residential"
                          class="border-2 border-pacGreen flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-black bg-pacGreen hover:opacity-90 md:py-4 md:text-lg md:px-10"
                        >
                          Residential
                        </a>
                      </div>
                      <div class="md:w-1/3 mt-3 rounded-md  sm:mt-0 sm:ml-3">
                        <a
                          href="/offset-commercial"
                          class="border-2 border-white flex items-center justify-center px-10 py-5 text-base font-medium rounded-md text-white bg-pacBlue bg-opacity-10 hover:bg-white hover:text-pacBlue md:py-4 md:text-lg md:px-10"
                        >
                          Commercial
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="hidden lg:block pl-10 w-1/2  relative"></div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div class="hidden about UET relative bg-gradient-to-r from-pacBlue to-pacLightBlue pt-10 pb-9">
          <div class="lg:mx-auto lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
            <div class="relative sm:py-16 lg:py-0">
              <div class="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
                <div class="relative pt-20 pb-10 rounded-2xl  overflow-hidden">
                  <img
                    class="absolute inset-0 h-full w-full object-cover"
                    src="https://www.unitedenergytrading.com/wp-content/uploads/hero-team.png"
                    alt=""
                  />

                  <div class="absolute inset-0 bg-gradient-to-t from-emerald-900 to-lime-400 mix-blend-multiply"></div>
                  <div class=" relative px-8">
                    <div>
                      <img
                        class="mx-auto"
                        src="https://www.unitedenergytrading.com/wp-content/themes/uet/assets/images/logo-white.svg"
                        alt="Workcation"
                      />
                    </div>
                    <blockquote class="mt-8">
                      <div class="relative text-lg font-medium text-white md:flex-grow">
                        <svg
                          class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-lime-200 opacity-40"
                          fill="currentColor"
                          viewBox="0 0 32 32"
                          aria-hidden="true"
                        >
                          <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                        </svg>
                        <p class="relative">
                          We understand the importance of sustainability and
                          ESG. As this ever-changing landscape grows, we’ve
                          identified more dynamic and diverse opportunities for
                          our traditional energy customers. From managing carbon
                          emissions to carbon sequestration, we’re excited to
                          offer “bundled” green packages designed to help both
                          private and public clients seamlessly adopt cleaner
                          energy solutions that best fit their needs.
                        </p>
                      </div>

                      <footer class="mt-4">
                        <p class="text-base font-semibold text-lime-200">
                          Thomas Williams, Executive Chairman & Executive Vice
                          President of Business Development
                        </p>
                      </footer>
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>

            <div class="mt-20 hidden md:block relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
              <div class="">
                <h2 class="text-3xl text-white font-extrabold tracking-tight sm:text-4xl">
                  Your Carbon Offset Renewable Partner
                </h2>
                <div class="mt-6 text-white space-y-6">
                  <p class="text-lg">
                    Our longstanding partnerships with{" "}
                    <a
                      href="https://www.callectiveenergy.com/"
                      target="blank"
                      class="font-bold hover:text-gray-100 hover:underline"
                    >
                      Callective Energy
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.unitedenergytrading.com/"
                      target="blank"
                      class="font-bold hover:text-gray-100 hover:underline"
                    >
                      United Energy Trading (UET)
                    </a>{" "}
                    allow us to continue providing the most cost-effective
                    sustainable green-energy solutions. Through purchasing
                    options like Carbon Offset Green Gas, United Green Energy
                    ensures the communities we service have the best in green
                    energy choices.
                  </p>
                  <p class="text-lg leading-7">
                    United Green Energy offers customers worldwide a multitude
                    of renewable energy services and products to help offset
                    carbon. Since teaming up with{" "}
                    <a
                      href="https://plantit2020.org/"
                      target="blank"
                      class="font-bold hover:text-gray-100 hover:underline"
                    >
                      Plant-It 2020
                    </a>{" "}
                    and{" "}
                    <a
                      href="https://www.4ocean.com/"
                      target="blank"
                      class="font-bold hover:text-gray-100 hover:underline"
                    >
                      4Ocean
                    </a>{" "}
                    only three years ago, the combined efforts of UGE, UET, and
                    Callective have helped plant over 11,409 trees and remove
                    over 3,500 pounds of trash from our oceans.
                  </p>
                  <p class="text-lg leading-7">
                    Helping the world reach a carbon-neutral status is our goal.
                    Through groundbreaking technology in the renewable and
                    sustainable energy space, we’re approaching our
                    responsibilities to the planet with urgency. We’re honored
                    and excited to be involved in the creation of a cleaner
                    future for the world.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="hidden bg-white mb-10">
          <div class="max-w-7xl mx-auto py-12 lg:px-8">
            <h1 class="text-center text-2xl tracking-tight font-medium text-gray-500">
              Companies that have offset their Carbon emissions with us...
            </h1>
            <div class="mt-6 flex justify-center">
              <div class="w-1/3 mx-auto py-8 px-8">
                <a class="flex" href="https://consultfgc.com/" target="blank">
                  <img
                    class="hover:opacity-70"
                    src="https://consultfgc.netlify.app/static/ConsultFGC_Logo-1714096432cc4fd9a8653a11b0a67248.png"
                    alt="Workcation"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="w-1/3 mx-auto rounded-md ">
            <a
              href="/offset-commercial"
              class="flex items-center justify-center px-10 py-5 border border-transparent text-base font-medium rounded-md text-white bg-emerald-500 hover:bg-emerald-700 md:py-4 md:text-lg md:px-10"
            >
              Commercial Offsets
            </a>
          </div>
        </div>{" "}
        <Footer />
      </div>
*/
